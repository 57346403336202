@import url('https://fonts.googleapis.com/css2?family=Cantarell&family=Poppins&family=Spectral&display=swap');
*{
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  overflow: hidden;
 
  font-family: "poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header-left{
  display: flex;
  flex-direction: column;
  align-items: center;  
  
}

/* .example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
} */