.app {
  display: flex;
  width: 80%;
  height: 100vh;
}
.app-body {
  width: 150%;
  margin-right: -17rem;
}
::-webkit-scrollbar {
  width: 8px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  background: rgb(214, 214, 214); 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: #9e9a9a; 
}