.header {
  display: flex;
  
}
.logo {
  width: 12.5%;
  border-radius: 7px;
  max-width: 100%;
  padding: -10rem;
}
.menu-left {
  display: flex;
  align-items: center;

}
.menu-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  
}
.sidebar {
  overflow: auto;
}
