

.link {
  text-decoration: none;
  color: forestgreen;
}
.overview-chart {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  width: 680px;
}
/*  */
.encounter-chart {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 550px;
  margin: 40px 10px 5px 3px;
  width: 510px;
}
.formal-chart {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 550px;
  margin: 40px 5px 5px 240px;
  width: 550px;
}
/*  */

